// Auth
export const API_CLIENT_URL = process.env.REACT_APP_FGI_API_CLIENT_URL || '';

//Base Admin URL
export const BASE_ADMIN_URL = process.env.REACT_APP_FGI_BASE_ADMIN_URL || '';

// Blob Endpoint For Media
export const BLOB_ENDPOINT_MEDIA = process.env.REACT_APP_FGI_BLOB_ENDPOINT_MEDIA || '';

// Endpoint For File Store

export const API_SEARCH_CLIENT_URL = process.env.REACT_APP_FGI_FILESTORE_ENDPOINT || '';