// Graph Query Service

import { gql } from "@apollo/client";


//MasterData Query
export const MASTERDATA_QUERY = gql`query MasterData{
    lOLMasterData{
        headerLogo{
            fileName
            path
        }
        bannerImage{
            fileName
            path
        }
        bannerText
        footerLeft
        footerCenter
        footerRight
        searchBannerImage{
          fileName
          path
        }
        errorBannerImage{
          fileName
          path
        }
        adobeAnalyticsScript
    }
  }`;

//MyLinks Query
export const MYLINKS_QUERY = gql`query MyLinks($roleNames: [String!]){
                                    lOLMyLinksCollection(where:  {
                                        publish:  {
                                            eq: true
                                        }
                                    }){
                                        items{
                                        title
                                        url
                                        roles(where:  {
                                            roleName:  {
                                                in: $roleNames
                                            }
                                        }){
                                            items{
                                            roleName
                                            }
                                        }
                                        publish
                                        }
                                    }
                                    }`;

//Brand Query
export const BRAND_QUERY = gql`query Brands($roleNames: [String!]) {
  lOLBrandsCollection(
    where: { publish: { eq: true } }
    sort: { sortOrder: ASC }
  ) {
    items {
      title
      logo {
        fileName
        path
      }
      headerLogo {
        fileName
        path
      }
      primaryColor
      heroImage {
        fileName
        path
      }
      nodeAlias
      isTrait
      sortOrder
      roles(where: { roleName: { in: $roleNames } }) {
        items {
          roleName
        }
      }
      publish
    }
  }
}`;

export const BRAND_FOLDER_QUERY = gql`query FoldersQuery($parentBrand: String){
        lOLFoldersCollection(where:  {
           active:  {
              eq: true
           }
           parentBrand:  {
              eq: $parentBrand
           }
        }){
          items{
            folderIcon
            folderName
            parentBrand
            active
          }
        }
      }`;
export const BRAND_FOLDER_DOCUMENT_QUERY = gql`query DocumentsQuery($parentBrand: String, $roleNames: [String!]){
        lOLDocumentsCollection(where:  {
           parentBrand:  {
              eq: $parentBrand
           }
          publishedinFileStore:  {
              eq: 1
           }
        }){
          items
          {
           title
           documentId
           fileAttachment
           folderName
           roles(where:  {
              roleName:  {
                 in: $roleNames
              }
           }){
            items{
              roleName
            }
           }
           parentBrand
           publishedinFileStore
          }
        }
      }`;
