import React from 'react';
import './Loader.scss';
import { CircularProgress } from '@mui/material';
import { useAppContext } from '../../context/AppContext';

function Loader() {
  const { loader } = useAppContext();

  return (
    <>
      {loader && loader.length > 0 &&
        <div className="loader-container" data-testid="loaderTestId">
          <CircularProgress />
        </div>
      }
    </>
  );
};
export default Loader;