import React from 'react';
import './App.scss';
import Footer from './components/Footer';
import Header from './components/Header';
import RouteManager from './routes/RouteManager';
import AppContextProvider from './context/AppContext';
import { BrowserRouter } from 'react-router-dom';
import { IApp } from './context/models';
import { MsalProvider } from '@azure/msal-react';
import Loader from './components/Loader';

function App({ instance }: IApp) {
  const accounts = instance.getAllAccounts();
  // console.log('msal instance', instance);
  const nameOfUser = localStorage.getItem('nameOfUser');

  return (
    <div className="App" data-testid="appTestId">
      <MsalProvider instance={instance}>
        <BrowserRouter>
          <AppContextProvider>
            <Loader />
            <Header />
            <RouteManager />
            <Footer />
          </AppContextProvider>
        </BrowserRouter>
      </MsalProvider>
    </div>
  );
}

export default App;
